<template>
    <form autocomplete="off" class="">
        <div>
            <div class="bd-callout mb-3 mt-0 bd-callout-info">
                <span>Allocated quantity: <strong>{{ item.allocated_quantity }}</strong> </span>;
                <span>Undispatched quantity: <strong>{{ item.undispatched_quantity  }}</strong></span>
            </div>
        </div>
        <div class="row gy-3">
            <div class="col-sm-6">
                <label for="unit" class="form-label">Select Unit</label>
                <select @change="calculateQuantity()" v-model="selectedUnit" 
                    :class="{ 'is-invalid': v$.data.unit_value.$error }"
                    name="unit" id="unit" class="form-select">
                <option disabled :value="null" >--choose unit--</option>
                    <option v-for="(unit, sIndex) in item.medicine.units" :key="sIndex" 
                    :value="unit">{{unit.title}}</option>
                </select>
                <div v-for="(item, index) in v$.data.unit_value.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-sm-6">
                <label class="form-label" for="inputUnitCount"> Unit Count </label>
                <input @change="calculateQuantity()" id="inputUnitCount" type="number" class="form-control" v-model="data.unit_count"
                :class="{ 'is-invalid': v$.data.unit_count.$error }" placeholder="Enter unit count" name="unit_count" />
                <div v-for="(item, index) in v$.data.unit_count.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div v-if="selectedUnit" class="col-12">
                <div class="alert small mb-0 alert-info">
                    <strong>NOTE!</strong> One {{ selectedUnit.title }} is counted as {{ selectedUnit.value }} quantit{{ selectedUnit.value > 1 ? 'ies' : 'y' }}
                </div>
            </div>
            <div class="col-12">
                <label class="form-label" for="inputQuantity"> Total quantity </label>
                <input disabled id="inputQuantity" type="number" class="form-control" v-model="data.quantity"
                :class="{ 'is-invalid': v$.data.quantity.$error }" placeholder="Quantity" name="quantity" />
                <div v-for="(item, index) in v$.data.quantity.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label">Dispatch Remark</label>
                <textarea v-model="data.dispatched_remark" class="form-control"
                :class="{ 'is-invalid': v$.data.dispatched_remark.$error }"
                rows="3" placeholder="Write remark" name="dispatched_remark"></textarea>
                <div v-for="(item, index) in v$.data.dispatched_remark.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="confirmCreate()"
                     type="button">Submit</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, minValue, maxValue, requiredIf, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
        item:{
            type: Object,
            required: true
        },
    },
    data(){
        return{
            data: {
                allocated_item_id: this.item.id,
                quantity: "",
                dispatched_remark:"",
                unit_count: 0,
                unit_value: null,
                unit_title: "",
            },
            selectedUnit: null
        }
    },
    validations() {
        return {        
            data:{
                quantity: {
                    required: helpers.withMessage("Quantity is required", required),
                    maxValue: maxValue(this.item.undispatched_quantity),
                    minValue: minValue(1)
                },            
                dispatched_remark: { 
                    required: helpers.withMessage("Your remark is required", required),
                },   
                unit_count:{
                    required: helpers.withMessage("Unit Count is required", required),
                    minValue: minValue(1)
                },
                unit_value:{
                    required: helpers.withMessage("Unit is required", required),
                    minValue: minValue(1)
                }         
            }
        }
    },
    computed:{
        completeQty(){
            return parseInt(this.data.quantity) 
            ? (parseInt(this.data.quantity) == this.item.undispatched_quantity)
            : true
        },
    },
    methods:{
        confirmCreate(){
            this.v$.$validate().then(result =>{
                if (!result) {this.alertError('Form is not valid'); return;}
                this.confirmAction({
                    text: `You are about to dispatch: <h6>${this.data.unit_count}-${this.data.unit_title} package of ${this.item.medicine.name}</h6> Total quantity: 
                    <h4>${this.$filters.number_format(this.data.quantity)}</h4> Ensure your details are accurate.`,
                }).then((result) => {
                    if (result.value) { this.create(); }
                });
            })
        },
        create(){
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post('/item-deliveries/create', this.data)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$emit('addItem', response.data.data)
                        this.resetForm()
                    }
                })
        },
        calculateQuantity(){
            if(this.selectedUnit && this.data.unit_count){
                this.data.unit_title = this.selectedUnit.title
                this.data.unit_value = this.selectedUnit.value
                this.data.quantity = parseInt(this.data.unit_count) * parseInt(this.selectedUnit.value)
            } 
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){

    }
}
</script>